import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Metadata from "../components/metadata"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
       <Metadata title="Home" description="This is my home page" />
  <div class="headerMain">
    <div class="block">
    
   <center> <img id="logo" src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/pawsome_couture_2022.jpg"/></center>
  </div>
</div>
  </Layout>
)

export default IndexPage
